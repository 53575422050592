import React, { useState } from 'react'

import Logo from './logo'

import appStoreSrc from '../images/badge-apple.svg'
import playStoreSrc from '../images/badge-google.svg'

import { Link } from "gatsby"

const hideDisplay = {
	display: 'none'
}

const NavFlyoutItem = ({to, title, text, icon, external=false}) => {
	let flyoutContent = (
	 	<>
		 	<div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-highlight text-white sm:h-12 sm:w-12">
	          {icon}
	        </div>
	        <div className="space-y-1">
	          <p className="text-base leading-6 font-medium text-gray-900">
	            {title}
	          </p>
	          <p className="text-sm leading-5 text-gray-500">
	            {text}
	          </p>
	        </div>
	    </>
	)
    let flyoutClassName = "-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
	if(!external){
		 return (
		 	<Link to={to} className={flyoutClassName}>
	        	{flyoutContent}
	      	</Link>
	      )
	}else{
		return (
		 	<a href={to} className={flyoutClassName}>
	        	{flyoutContent}
	      	</a>
	      )
	}
}

const Navbar = () => {	
	const [open, setOpen] = useState(false);
	const [showFlyout, setFlyout] = useState(false);
	return (
		<div>
		 	<div className="max-w-screen-xl mx-auto px-4 sm:px-6">
			      <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
			        <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
			          <div className="flex items-center justify-between w-full md:w-auto">
			            <Logo />
			            <div className="-mr-2 flex items-center md:hidden">
			              <button onClick={() => {
			              		setOpen(true);
			              	}} 
			              	type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
			                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
			                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
			                </svg>
			              </button>
			            </div>
			          </div>
			        </div>
			        <div className="hidden md:block">
			          <Link to="/how-it-works" className="font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">How It Works</Link>
			          <Link to="/pricing" className="ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Pricing</Link>
			          <div className="relative inline-flex">
			          	<button type="button" onClick={() => {
			              		setFlyout(!showFlyout);
			              	}} className="ml-10 group inline-flex items-center space-x-2 text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">
						    <span>Solutions</span>
						    <svg className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
						      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
						    </svg>
						  </button>
						 {showFlyout && 
						 	<div className="absolute z-50 left-1/2 transform -translate-x-1/2 mt-12 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
							    <div className="rounded-lg shadow-lg">
							      <div className="rounded-lg shadow-xs overflow-hidden">
							        <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
							          <NavFlyoutItem to="/gps-timesheets" title="GPS Time Tracker" text="Locate every clock in with optional geofences for mobile employees" icon={
							          	<svg className="flex-shrink-0 h-6 w-6 text-white" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path></svg>
							          } />
									  <NavFlyoutItem to="/employee-leave-management-system" title="Leave Management" text="Record and track employee leave online" icon={
									  	<svg className="flex-shrink-0 w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
										  <path clipRule="evenodd" fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" />
										</svg>
									  } />
									  <NavFlyoutItem to="/rota-planner" title="Rota Planner" text="Plan your shift rota online" icon={
									  	<svg className="flex-shrink-0 h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" /></svg>
									  } />
							          <NavFlyoutItem to="/xero-timesheet-app" title="Xero Payroll Integration" text="Export your timesheets from TimeKeeper to Xero Payroll" icon={
							          	<svg className="flex-shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" /></svg>
							          } />
							          <NavFlyoutItem to="/sage-payroll-integration" title="Sage Payroll Integration" text="Export your timesheets from TimeKeeper to Sage 50" icon={
							          	<svg className="flex-shrink-0 h-6 w-6 text-white" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clipRule="evenodd"></path></svg>
							          } />
							          <NavFlyoutItem to="/brightpay-timesheet-integration" title="BrightPay Integration" text="Export your timesheets from TimeKeeper to BrightPay" icon={
							          	<svg className="flex-shrink-0 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
							          } />
							           <NavFlyoutItem to="/moneysoft-timesheet-integration" title="Moneysoft Integration" text="Export your timesheets from TimeKeeper to Moneysoft" icon={
							          <svg xmlns="http://www.w3.org/2000/svg"  className="flex-shrink-0 h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
</svg>
							          } />
							          <NavFlyoutItem to="/remote-employees" title="Remote Employees" text="Keep your distributed team time and attendance in sync" icon={
							          	<svg className="flex-shrink-0 h-6 w-6 text-white" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd"></path></svg>
							          } />
							          <NavFlyoutItem to="/blog" title="Blog" text="Read our tips, tricks and guides to managing time and attendance" icon={
							            <svg className="flex-shrink-0 h-6 w-6 text-white" fill="currentColor" viewBox="0 0 20 20"><path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path></svg>
							          } />
							          <NavFlyoutItem to="/employee-instant-messenger" title="TimeKeeper Chat" text="Communicate with your team via our instant messenger" icon={
							            <svg className="flex-shrink-0 h-6 w-6 text-white" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
										  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"></path>
										</svg>
							          } />
							          <NavFlyoutItem external to="https://help.timekeeper.co.uk" title="Help Centre" text="Need some help? Check out our guides here or contact us" icon={
							            <svg className="flex-shrink-0 h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
							            </svg>
							          } />
							        </div>
							        <div className="p-5 bg-gray-100 sm:p-8">
							          <div className="-m-3 p-3 flow-root space-y-1 rounded-md hover:bg-gray-100 transition ease-in-out duration-150">
							            <div className="flex items-center space-x-3">
							              <div className="text-base leading-6 font-medium text-gray-900">
							                Download the TimeKeeper App
							              </div>
							            </div>
							            <div className="flex">
							            	<div className="flex h-8 mt-2 flex-row">
							            		<a href="https://apps.apple.com/gb/app/timekeeper-time-and-attendance/id1387606323" className="w-32 h-32 text-gray-400 hover:text-gray-500"><img src={appStoreSrc} alt="TimeKeeper on iOs App Store" /></a>
        										<a href="https://play.google.com/store/apps/details?id=uk.co.timekeepr&hl=en_GB" className="ml-4 w-32 h-32 text-gray-400 hover:text-gray-500"><img src={playStoreSrc} alt="TimeKeeper on Google Play Store" /></a>
        									</div>
        								</div>
							          </div>
							      </div>
							      </div>
							      
							    </div>
							  </div>
						 }
			          </div>
			          <Link to="/blog" className="ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Blog</Link>
			        </div>

			        <div className="hidden md:absolute md:flex md:flex-row md:items-center md:justify-end md:inset-y-0 md:right-0">
			          <span className="inline-flex rounded-md shadow">
			            <a href="https://app.timekeeper.co.uk/register" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-highlight hover:text-white focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-white-700 transition duration-150 ease-in-out">
			              <span className="hidden lg:inline-flex">Start your 14 day free trial</span>
			              <span className="inline-flex lg:hidden">Register</span>
			            </a>
			          </span>
			          <span className="ml-2 inline-flex rounded-md shadow">
			            <a href="https://app.timekeeper.co.uk/login" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-highlight-600 bg-white hover:text-highlight-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-highlight-700 transition duration-150 ease-in-out">
			              Log in
			            </a>
			          </span>
			        </div>
			      </nav>
		    </div>

		    <div style={open ? null : hideDisplay} className="absolute top-0 inset-x-0 z-50 p-2 md:hidden">
		      <div className="rounded-lg shadow-md transition transform origin-top-right">
		        <div className="rounded-lg bg-white shadow-xs overflow-hidden">
		          <div className="px-5 pt-4 flex items-center justify-between">
		            <div>
		              <Logo />
		            </div>
		            <div className="-mr-2">
		              <button onClick={() => {
		              	setOpen(false)
		              }} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
		                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
		                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
		                </svg>
		              </button>
		            </div>
		          </div>
		          <div className="px-2 pt-2 pb-3">
		            <Link to="/how-it-works" className="block px-3 py-2 rounded-md text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">How It Works</Link>
		            <Link to="/pricing" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Pricing</Link>
		            <Link to="/blog" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Blog</Link>
		            <a href="https://help.timekeeper.co.uk" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-500 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Support</a>
		          </div>
		          <div>
		            <a href="https://app.timekeeper.co.uk/register" className="block w-full px-5 py-3 text-center font-medium text-white bg-highlight-500 hover:bg-highlight-100 hover:text-white focus:outline-none focus:bg-highlight-100 focus:text-white-700 transition duration-150 ease-in-out">
		              Start your 14 day free trial
		            </a>
		            <a href="https://app.timekeeper.co.uk/login" className="block w-full px-5 py-3 text-center font-medium text-highlight-600 bg-gray-50 hover:bg-gray-100 hover:text-highlight-700 focus:outline-none focus:bg-gray-100 focus:text-highlight-700 transition duration-150 ease-in-out">
		              Log in
		            </a>
		          </div>
		        </div>
		      </div>
		    </div>
		</div>
	)
}

export default Navbar;