import React from 'react';


const Banner = ({ title, btnTitle=null, btnAction=null }) => (
	<div className="bg-highlight">
	  <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
	    <div className="flex items-center justify-between flex-wrap">
	      <div className="w-0 flex-1 flex-wrap items-center w-full">
	        <p className="ml-3 text-lg font-bold text-white">
	        {title}
	        </p>
	      </div>
	      {btnTitle != null && 
	      	<div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
		        <div className="rounded-md shadow-sm">
		          <div onClick={btnAction} className="flex cursor-pointer items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-highlight-600 bg-white hover:text-highlight-600 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
		            {btnTitle}
		          </div>
		        </div>
		      </div>
		  }			     
	      {false && 
	      	<div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
		        <button type="button" className="-mr-1 flex p-2 rounded-md hover:bg-highlight-500 focus:outline-none focus:bg-highlight-500 sm:-mr-2 transition ease-in-out duration-150">
		          <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
		            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
		          </svg>
		        </button>
		      </div>
		  }
	    </div>
	  </div>
	</div>
)

export default Banner;

