import React from 'react';
import appStoreSrc from '../images/badge-apple.svg'
import playStoreSrc from '../images/badge-google.svg'

const AppStoreIcons = () => (
	<div className="mt-8 flex">
        <a href="https://apps.apple.com/gb/app/timekeeper-time-and-attendance/id1387606323" className="w-32 h-32 text-gray-400 hover:text-gray-500"><img src={appStoreSrc} alt="TimeKeeper on iOs App Store" /></a>
        <a href="https://play.google.com/store/apps/details?id=uk.co.timekeepr&hl=en_GB" className="ml-4 w-32 h-32 text-gray-400 hover:text-gray-500"><img src={playStoreSrc} alt="TimeKeeper on Google Play Store" /></a>
    </div>
) 

export default AppStoreIcons;