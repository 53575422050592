/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"

import Banner from "./banner";
import moment from 'moment';

import '../styles/tailwind.css'
import '../styles/utils.css'

const BLOG_POST_PATH = '/blog/posts/introducing-timekeeper-v2-0';


const Layout = ({ children }) => {

  // Load scripts
  useEffect(() => {
    // add rewardful script
    (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');

    let cookieFirstScript = document.createElement('script');
    cookieFirstScript.src = "https://consent.cookiefirst.com/sites/timekeeper.co.uk-a6a29758-4a84-46bd-82ae-084662234a33/consent.js";
    document.head.appendChild(cookieFirstScript);

    window.consentCallback = (e) => {

      var consent = e.detail || window.CookieFirst.consent;
      if(!consent) {
        return;
      }


      if(consent.performance){

        //add rewardful script
        let rewardfulScript = document.createElement('script');
        rewardfulScript.async = true;
        rewardfulScript.src = 'https://r.wdfl.co/rw.js';
        rewardfulScript.setAttribute('data-rewardful', 'e49a1f');

        document.head.appendChild(rewardfulScript);

        let utmTrackScript = document.createElement('script');
        utmTrackScript.src = '/utm_track.js';
        document.head.appendChild(utmTrackScript);


      }

      if(consent.functional){

      }


      if(consent.necessary){

      }

    };

    window.addEventListener('cf_consent', window.consentCallback);
    window.addEventListener('cf_init', window.consentCallback);

  }, [])


  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "TimeKeeper",
    "url": "https://www.timekeeper.co.uk",
    "logo": "https://www.timekeeper.co.uk/images/tk-logo-square.png"
  };

  return (
    <>
      <div>
        <Helmet>
            <meta name="apple-itunes-app" content="app-id=1387606323" />
            <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        </Helmet>
        <main className="mt-4">{children}</main>
        <footer>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
