/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description, image, fullImageSrc=null, pathname=null, type=null}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl,
            title
            defaultDescription
            author,
            defaultImage,
            twitterUsername
          }
        }
      }
    `
  )

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: fullImageSrc != null ? fullImageSrc : `${siteUrl}${image || defaultImage}`,
    url: pathname != null ? `${siteUrl}/${pathname}` : siteUrl,
    type: type
  }


  return (
    <Helmet 
      htmlAttributes={{
        lang: 'en'
      }}
      title={seo.title} 
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          name: `image`,
          content: seo.image,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:type`,
          content: seo.type != null ? seo.type : 'website',
        },
        {
          name: `twitter:card`,
          content: 'summary_large_image'
        },
      
        {
          name: `twitter:creator`,
          content: twitterUsername
        },
        {
          name: `twitter:title`,
          content: seo.title
        },
        {
          name: `twitter:description`,
          content: seo.description
        },
        {
          name: `twitter:image`,
          content: seo.image
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
