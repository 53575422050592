import React from 'react';

import Logo from './logo'

import { Link } from "gatsby"
import AppStoreIcons from './app-store-icons';


const Footer = () => (
	<div className="bg-white">
	  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
	    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
	      <div className="xl:col-span-1">
	        <Logo />
	        <p className="mt-4 text-gray-500 text-base leading-6">
	         	Simple Time and Attendance Software <br /> for Businesses.
	        </p>
	        <AppStoreIcons />
	      </div>
	      <div className="xl:mt-0 xl:col-span-2">
	        <div className="md:grid md:grid-cols-3 md:gap-8">
	          <div>
	            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
	              Use Cases
	            </h4>
	            <ul className="mt-4">
	              <li className="mt-4">
	                <Link to="/rota-planner" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Rota Planner
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/gps-timesheets" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  GPS Timesheets
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/xero-timesheet-app" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Xero Payroll
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/sage-payroll-integration" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Sage Payroll
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/brightpay-timesheet-integration" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  BrightPay Integration
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/moneysoft-timesheet-integration" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Moneysoft Integration
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/best-timesheet-app-for-construction" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Construction
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/timesheet-software-for-manufacturing" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Manufacturing
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/timesheet-app-for-cleaning-companies" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Cleaning
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/gardening-landscaping-time-tracking" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Gardening & Landscaping
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/sage-timesheets-alternative" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Sage Timesheets Alternative
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/remote-employees" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Remote Employees
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/visitor-management" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Visitor Management
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/employee-instant-messenger" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Employee Messaging
	                </Link>
	              </li>

	            </ul>
	          </div>
	          <div className="mt-12 md:mt-0">
	            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
	              Support
	            </h4>
	            <ul className="mt-4">
	              <li>
	                <Link to="/how-it-works" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  How It Works
	                </Link>
	              </li>
	              <li className="mt-4">
	                <Link to="/pricing" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Pricing
	                </Link>
	              </li>
	              <li className="mt-4">
	                <a href="https://help.timekeeper.co.uk" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Documentation
	                </a>
	              </li>
	              <li className="mt-4">
	                <a href="https://status.timekeeper.co.uk" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  System Status
	                </a>
	              </li>
	              <li className="mt-4">
	                <a href="https://feedback.timekeeper.co.uk/changelog" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Changelog
	                </a>
	              </li>
	              <li className="mt-4">
	                <a href="https://feedback.timekeeper.co.uk" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Feedback
	                </a>
	              </li>
	              <li className="mt-4">
	                <a href="mailto:info@timekeeper.co.uk" className="text-base leading-6 text-gray-500 hover:text-gray-900">
	                  Contact Us
	                </a>
	              </li>
	            </ul>
	          </div>
	          <div className="mt-12 md:mt-0">
	            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
	              Resources
	            </h4>
							<ul className="mt-4">
								<li className="mt-4">
									<Link to="/blog" className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Blog
									</Link>
								</li>
								<li className="mt-4">
									<Link to="/privacy" className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Privacy
									</Link>
								</li>
								<li className="mt-4">
									<Link to="/terms" className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Terms
									</Link>
								</li>
								<li className="mt-4">
									<a href="https://timekeeper-kb.notion.site/Careers-at-TimeKeeper-bcf828fbb4974a06855c3014b5400cc3"
										 className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Careers
									</a>
								</li>
								<li className="mt-4">
									<Link to="/cookie-policy" className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Cookie Policy
									</Link>
								</li>
								<li className="mt-4">
									<div className="text-base leading-6 text-gray-500 hover:text-gray-900" onClick={() => {
										if ("CookieFirst" in window) {
											window.CookieFirst.openPanel()
										}
									}}>
										Manage Cookie Consent
									</div>
								</li>
								<li className="mt-4">
									<Link to="/calculators/timesheet-calculator"
												className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Timesheet Calculator
									</Link>
								</li>
								<li className="mt-4">
									<Link to="/calculators/hours-minutes-to-decimal-calculator"
												className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Hours and Minutes to Decimal Calculator
									</Link>
								</li>
								<li className="mt-4">
									<Link to="/calculators/decimal-to-hours-minutes-calculator"
												className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Decimal Time to Hours Minutes Calculator
									</Link>
								</li>
								<li className="mt-4">
									<Link to="/free-timesheet-templates"
												className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Free Timesheet Templates
									</Link>
								</li>
								<li className="mt-4">
									<Link to="/free-rota-templates" className="text-base leading-6 text-gray-500 hover:text-gray-900">
										Free Rota Templates
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-12 border-t border-gray-200 pt-8">
				<p className="text-base leading-6 text-gray-400 xl:text-center">
					&copy; {new Date().getFullYear()} TimeKeeper is a trading name of Artificialdev Ltd. All rights reserved.
	      </p>
	      <p className="mt-4 text-sm leading-6 text-gray-400 xl:text-center ">
	         	Built in Belfast, N.Ireland. Company Number: NI647093, VAT Number: GB337245501
	        </p>
	    </div>
	  </div>
	</div>
)

export default Footer;
