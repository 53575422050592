import React from "react"

import logoSrc from "../images/logo-dark.svg"

const Logo = () => (
	<a href="/">
      <img className="h-8 w-40 sm:h-10" src={logoSrc} alt="TimeKeeper Logo" />
    </a>
)

export default Logo;